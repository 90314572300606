.home-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home-splash-logo {
    margin: 10px;
    width: 30%;
}

.home-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #8FAEB1;
    width: 100%;
    color: white;
    font-family: Arial;
    padding-bottom: 30px;
}

.home-subtitle {
    font-size: 22px;
    padding-left: 20px;
}

.home-paragraph {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    text-wrap: wrap;
}

.home-acknowledgements {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 6px;
}

.home-acknowledgement {
    font-size: 18px;
}