.header-container {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #8FAEB1;
}

.header-container img {
    padding: 20px 10px 20px 20px;
    height: 50px;
}

.header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.header-container p {
    font-size: 24px;
    color: white;
    font-family: Arial;
}

.header-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}

.header-option {
    padding: 12px;
    margin: 8px;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    height: 100%;
    background-color: #8FAEB1;
    color: white;
    font-size: 24px;
}

.header-option:hover {
    background-color: #819d9f;
}