.app-wrapper {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial;
}

.app-wrapper p {
  font-family: Arial;
}

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

