.result-preview {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 4px;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    border: 2.5px solid #8FAEB1;
    width: 100%;
}

.result-preview-title {
    font-weight: 600;
    margin: 0 auto;    
}

.result-preview-tags {
    color: gray;
    margin: 1px auto;
    padding-left: 8px;
    font-size: 14px;
}

.tag-title {
    color: black;
}

.result-preview:hover {
    background-color: #b1c6c8;
    color: white;
}