.lesson-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.lesson-title {
    font-size: 22px;
    font-weight: 500;
}

.pdf-navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
}

.navigation-button-container button {
    padding: 12px;
    width: 80px;
    border: none;
    cursor: pointer;
    background-color: #9ab6b9;
    color: white;
}

.navigation-button-container button:hover {
    background-color: #728b8e;
}

.button-half-left {
    border-radius: 12px 0 0 12px;
}

.button-half-right {
    border-radius: 0 12px 12px 0;
}

.lesson-metadata-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 0px
}

.download-button {
    padding: 12px 24px 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 16px;
    margin-left: 24px;
    background-color: #9ab6b9;
    text-decoration: none;
    color: white;
}

.download-button:hover {
    background-color: #728b8e;
}

.pdf-page-info {
    margin-right: 20px;
}