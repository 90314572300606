.search-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #819d9f;
    padding: 24px;
    border-radius: 24px;
    border: none;
    margin-top: 45px;
    width: 700px;
}

.builder-input {
    padding: 10px;
    border: 2px;
    margin: 2px;
    width: calc(100% / 2 );  /* Gives half the bar to the search field */
    box-sizing: border-box;  /* Includes padding in the width */
    height: 40px; /* Sets the height explicitly for cross-browswer consistency */
}

.dropdown {
    width: calc(100% / 4 ); /* Sets the dropdown menu space to a quarter of the search bar */
}
.query-submit-button {
    cursor: pointer;
    background-color: #9ab6b9;
    color: white;
    font-size: 16px;
    font-weight: 500;
    width: auto;
}

.query-submit-button:hover {
    background-color: #728b8e;
}

.left {
    border-radius: 12px 12px 12px 12px;
}
.right {
    border-radius: 12px 12px 12px 12px;
}
.center {
    border-radius: 12px 12px 12px 12px;
}