.search-results-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 0 40px 0 40px;
    max-height: 600px;
    overflow-y: scroll;
}